.x-btn {
  @apply min-h-[32px] pt-0 pb-0 inline-flex items-center justify-center font-normal text-[var(--x-text-color)] text-center align-middle select-none bg-transparent border border-transparent px-[0.75rem] py-1 text-[1rem] leading-[1.5] rounded transition-colors duration-150 cursor-pointer;
  &:disabled {
    @apply opacity-50;
  }
}

.x-btn-primary {
  @apply text-white border-[var(--x-primary)] bg-[var(--x-primary)];
  &:hover,
  &:focus {
    @apply text-white border-[#003b80] bg-[#00418d];
  }
}

.x-btn-light {
  @apply text-[var(--x-secondary)] border-[var(--x-light-color)] bg-[var(--x-light-color)];
  &:hover,
  &:focus {
    @apply text-[#212529] border-[#cdd6e4] bg-[var(--x-light-gray-color)];
  }
}

.x-btn-white {
  @apply text-[var(--x-secondary)] border-white bg-white;
  &:hover,
  &:focus {
    @apply text-[#212529] border-[#cdd6e4] bg-[var(--x-light-gray-color)];
  }
}
