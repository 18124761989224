$blue: #4c9aff;
$black: #172b4d;
$yellow: #f8be1e;
$wild-sand: #f4f4f4;
$logan: #a1a8cc;
$alto: #d5d5d5;
$light: #eef1f6;
$light-blue: #dde8f1;
$hawkes-blue: #d5dce9;
$cyan: #e4fcff;

$leave-paid: #ff6771;
$leave-un-paid: #3496fb;
$leave-wfh: #aba1ff;

$border-dropdown-gray: #e6edf1;
$border-color: #ddd;
$border: 1px solid $border-color;
$border-notification: #091e4214;
$form-border-color: #cddbe3;
$form-border: 0.8px solid $form-border-color;

$option-hover-color: #f5faff;
$option-selected-color: #ebf5ff;
$hover-color: #ebecf0;
$row-hover-color: #fafbfc;
$row-selected-color: #fafafb;
$primary: #0053b3;
$secondary: #42526e;
$success: #77be79;
$warning: #f2a403;
$danger: #ec4d57;
$dark: $black;
$info: #03cfec;
$light-gray: #737373;
$bg-gray: #F5F6F8;
$bg-light-white: #f5f6f8;
$orange: #feac58;
$carrot-orange: #ff9a1f;
$yellow-at-risk: #f1a404;

$report-status-green: $success;
$report-status-yellow: #fcc045;
$report-status-red: #ec4d57;
$report-status-gray: #c9c9c991;

$task-epic-color: $yellow;
$task-status-color-default: #737373;
$task-status-color-completed: #77be79;
$task-status-color-todo: $task-status-color-default;
$task-status-color-progress: #00c4ff;
$bg-light-gray: #ebedf0;
$slate-gray: #6b778c;

$okr-dashboard-color-secondary: #6b778c;
$okr-dashboard-progress: #85dafa;
$okr-dashboard-gold: #fff421;
$okr-dashboard-silver: #9b9b9b;
$okr-dashboard-bronze: #ff9e5d;

$color-weekday: #355676;
$color-week: #005dc985;
