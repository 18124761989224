@import '../../abstracts/variables';

.mat-datepicker-content {
  height: max-content !important;
  @apply shadow-lg;
  box-shadow: var(--tw-shadow) !important;

  * {
    font-family: $font-family-base !important;
  }

  .mat-datepicker-content-container {
    .mat-calendar {
      height: max-content !important;

      .mat-calendar-header {
        padding: 15px 15px 0 15px;

        .mat-calendar-controls {
          margin: 0 !important;
          background-color: var(--x-primary);
          color: white !important;
          border-radius: 3px 3px 0 0;
          * {
            color: white !important;
          }
          .mat-calendar-arrow {
            fill: white !important;
          }
        }
      }

      .mat-calendar-content {
        padding: 0 15px 15px 15px !important;

        .mat-calendar-table {
          padding-top: 10px;
          border: 1px solid #e9edf0;
          border-radius: 0 0 3px 3px;

          .mat-calendar-table-header {
            tr {
              th {
                padding: 8px 0 0 0;
                font-weight: 600;
                color: #355676;
              }
              th:first-child,
              th:last-child {
                color: red;
              }
            }

            .mat-calendar-table-header-divider {
              display: none;
            }
          }
          .mat-calendar-body {
            tr {
              .mat-calendar-body-label {
                @apply invisible;
                &[colspan="7"] {
                  @apply hidden;
                }
              }
              .mat-calendar-body-cell-container {
                .mat-calendar-body-cell {
                  &:hover {
                    .mat-calendar-body-cell-content {
                      background-color: var(--x-border);
                    }
                  }
                  .mat-calendar-body-cell-content {
                    top: 10%;
                    left: 10%;
                    width: 80%;
                    height: 80%;

                    &.mat-calendar-body-selected {
                      background: var(--x-primary);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
