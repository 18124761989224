.overlay-fade-in {
  -webkit-animation: overlay-fade-in 0.5s linear;
  animation: overlay-fade-in 0.5s linear;
}

.overlay-fade-in-0-2s {
  -webkit-animation: overlay-fade-in 0.2s linear;
  animation: overlay-fade-in 0.2s linear;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-21 15:6:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
